///Break Points
$grid: 1400px;
$wide: 1200px;
$break: 991px;
$wrappernarrow:850px;
$tablet: 768px;
$handheld: 600px;
$mobile: 400px;
$iphone5: 320px;

///Fonts
$body-font:'Public Sans', sans-serif;
$display: 'franklin-gothic-atf', sans-serif;;

///Colors
$primary-color:#C08C0C;
$secondary-color:#9db441; 

$accent1:#FFD400;

$dark-gray:#636466;
$mid-gray:#eeeeee;
$light-gray:#F6F6F6;

$offwhite: #F1F1EF;

///Animation 
$duration-short:200ms;
$duration:500ms;
$duration-long:2500ms;