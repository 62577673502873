#pageStudentLogin,
#pageCheckoutLogin {
    .login-pill-head{
        background-color: $offwhite;
        margin: 0 0 18px;
        text-align: center;
        padding: 7px 0;     
        font-family: $display;   
        font-weight: 500;
        font-size: 18px;
    }
    .btn-primary{
        text-transform: uppercase;
        font-family: $display;
        &:hover{
            background-color:#000;
        }
    }
    #pageHeader {
        text-align: center;
        padding: 20px 0;
    }    
    #contentModule1 .row .row {
        flex-wrap: nowrap;
        @media screen and (max-width:$tablet){
            flex-direction: column; 
            margin: 0;
        }
    }
    #existingStudentDiv{
        &:after {
            @media screen and (min-width:$break) {
                content:'';
                height: 300px;
                background-color: #000;
                width: 1px;
                right: -22px;
                top: calc(50% - 150px);
                position: absolute;
                display: block;
            }
        }
        @media screen and (max-width:$tablet){
            padding:0;
        }
        .login-pill-head{ 
            @media screen and (min-width:$break) {
                margin: 0 -15px 18px;
            }
        }
        .row{
            flex-wrap: nowrap;
            width: 100%;
        }
        .sectionHeader{
            display: none;
        }
    }
    #existingStudentDiv .col-md-6,
    .newStudentDivContainer{
        background-color:$offwhite;
        padding:20px;
        text-align: center;
        display: flex;
        vertical-align: middle;
        min-height: 450px;
        @media screen and (min-width:$tablet){
            flex-direction: column;
        }
        @media screen and (max-width:$tablet){
            margin: 0 0 20px;
        }
        a{
            text-decoration: underline;
        }
        #portalExternalLogonLinkPanel{
            max-width: 370px;
            margin: 0 auto 20px;
        }
        #variableContentBlockPG0014{
            margin-top: 20px;
            p{
                margin:0 0 5px;
            }
        }
        h3{
            font-weight: 500;
        }
        h2,h3{
            font-family:$display;
            color:#000;
            font-size: 16px;
            margin-bottom: 18px;
            min-height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (min-width:1200px) {
                min-height: 20px;
            }
        }
        p {
            font-size: 14px;
        }
        .btn{
            margin: 0 auto;
            float: none;
        }
    }    
    #portalNativeLoginPanel,
    #newStudentDiv{
        @media screen and (min-width:$tablet){
             margin-left:30px;
        }
    }
    #newStudentDiv {
        @media screen and (max-width:$tablet){
            padding:0;
        }
    }
    #newStudentDiv,
    .newStudentDivContainer {
        flex-direction: column;
    }
    #portalNativeLoginPanel > div{
        display: none;
    }  
    div.existingStudentCredentialsContainer{
        margin-top:10px;
    }
    .forgot-credential-container{
        flex-direction: column;
        a{
            color:#000; 
            margin:5px 0 2px;
        }
    }
    .card-body{
        flex:0;
        padding:0;
        margin-bottom: 0;
    }
    .info{
        position: absolute;
        width: 20px;
        height: 20px;
        right: 13px;
        top: 10px;
        z-index: 25;
        cursor:pointer;
    }
    // [name="portalLogonForm"]{
    //     margin:auto;
    // }
    .tooltip{
        background-color: #000;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 13px;
        z-index: 5;
        opacity: 1;
        display: none;
        top: 38px;
        width: 170px;
        right: 2px;
        position: absolute;
        margin: auto;
        img{
            position: absolute;
            width: 20px;
            top: -16px;
            right: 10px;
            height: 29px;
            vertical-align: top;
        }
        &.on {
            display: block !important;
        }
    }  
}