@font-face {
    font-family: "Public Sans Web";
    src: 
        url("/fonts/PublicSans-Regular.otf") format('otf'),
        url("/fonts/PublicSans-Regular.woff") format("woff"), 
        url("/fonts/PublicSans-Regular.woff2") format("woff2"), 
        url("/fonts/PublicSans-Regular.ttf") format("truetype");
}

#mainContentContainer{
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: $display;
    }
}

h5 a {
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;    
    color:#000 !important;
}

li,
p,
small {
    font-family: $body-font;
}