.info-props {
    background-color:#F8F8F8;
    display: flex;
    padding:40px 0;
    justify-content: space-between;
    @media screen and (max-width:800px) {
        flex-direction: column;
    }   
    .info-text {
        width: 50%;
        p{
            max-width: 330px;
            line-height: 22px;
        }
        @media screen and (max-width:800px) {
            padding:25px 15px 0;
        }         
    }
    .info-image{
        width: 46%;
    }
    .info-text,
    .info-image{
        @media screen and (max-width:800px){
            width: 100%;
        }
    }
    h3,h5 {
        font-weight: 600;
    }
}