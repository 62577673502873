footer{
    margin-top: 100px;
    border-top:3px solid $accent1;
    position: relative;
    background-color: #fff;
    p,
    small,
    i,
    a {
        color:#00000080;
    }
    .footer1 {
        margin-top: 20px;
    }    
    .footer-logo {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        width: 90px;
        margin: 0 auto;
    }
    //coporate portal
    #footer2Container {
        display: none;
    }
    #footer1Container {
        background-color: #ffffff !important;
    }
    .footer-container{
        display: flex;
        justify-content: space-between;
        @media screen and (max-width:800px) {
            flex-direction: column;
            padding-top: 60px;
        }           
        .left,
        .right{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media screen and (max-width:799px) {
                text-align: center;
            }
        }
        .left {
            justify-content: flex-end;
            nav {
                display: flex;
                flex-direction: row;
                @media screen and (max-width:799px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                li{
                    &:first-of-type {
                        a {
                            padding-left:0;
                        }
                    }
                }
            }            
        }
        .right{
            @media screen and (min-width:800px) {
                align-items: end;
            }
            @media screen and (max-width:799px) {
                padding-top:20px;
            }
            .d1{
                width: 120px;
                margin-bottom: 15px;
                @media screen and (max-width:799px) {
                    margin:0 auto 15px;
                    display: block;
                }
            }
        }
        .social-media{
            margin-top: 10px;
            i {
                margin-right: 12px;
                font-size: 20px;
            }
        }        
    }
    small{
        padding-bottom: 7px;
    }
}

#footer2Sub3{
    display:none;
}