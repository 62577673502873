html {
    font-size: 87.5%
}

body {
    color: #000;
    font: normal 1em/1.4 Arial, Helvetica, sans-serif;
    font-family: $body-font;
}

h1 {
    font-weight: normal;
    font-size: 28px;
    text-transform: uppercase
}

h1 a, h1 a:link, h1 a:visited {
    color: #232323;
    text-decoration: none
}

h1 a:active, h1 a:focus, h1 a:hover {
    color: #626262
}

h2 {
    font-size: 1.3em;
    margin-bottom: .5em
}

h3 {
    font-size: 1.4em;
    color: #000;
    font-family: 'ClassicGrotesqueW01-Lt';
    font-weight: normal;
    margin-bottom: .3em
}

h4 {
    font-size: 1.2em;
    color: #232323;
    font-size: 1em;
    margin-bottom: .1em
}

h5 {
    color: #52514f;
    font-size: 1em;
    font-weight: normal
}

h6 {
    font-size: .8em;
    margin: 0 0 .5em
}

h1.outside {
    margin: .3em 0 0
}

h1+img {
    margin-top: 15px
}

h1 b, h3 b {
    font-weight: normal
}

p {
    margin: 0 1em 1.8em 0
}

sup {
    font-size: .57em;
    vertical-align: top
}

sub {
    font-size: .57em
}

a, a:link {
    color: #707372;
    text-decoration: none
}

a:active, a:focus, a:hover {
    text-decoration: underline
}

input, textarea, select {
    font-family: ClassicGrotesque, Arial, sans-serif
}


/* Main page background */
#pageContainer {
  /* background-color: <Insert Color Code>; */
}

/* Header 1 */
.navbar-dark.bg-dark {
  /* background-image: none; */
  /* background-color: <Insert Color Code>; */
}

/* Header 3 global navigation menu */
.navbar-light.bg-light {
  /* background-image: none; */
  /* background-color: <Insert Color Code>; */
}

/* Header 3 global navigation menu label */
.navbar-light.bg-light .navbar-nav > li > a  {
  /* color: <Insert Color Code>; */
}

/* Header 3 global navigation drop down menu */
.navbar-nav>li>.dropdown-menu {
  /* background-color: <Insert Color Code>; */
}

/* Header 3 global navigation drop down menu label */
.dropdown-menu > li > a, .dropdown-menu .subMenu > li > a {
    /* color: <Insert Color Code>; */
}

/* Footer 1 */
#footer1Wrapper .container {
  /* background-color: <Insert Color Code>; */
}

/* Footer 2 */
#footer2Wrapper .container {
  /* background-color: <Insert Color Code>; */
}

/* Section Header */
.sectionHeader {
  /* background-image: none; */
  /* background-color: <Insert Color Code>; */
  /* border-color: <Insert Color Code>; */
}

/* Secondary Section Header */
.sectionHeaderSecondary {
  /* background-image: none; */
  /* background-color: <Insert Color Code>; */
  /* border-color: <Insert Color Code>; */
}

/*Certificate Stream Container formatting */
.certificateStreamContainer.card-info p {
    margin:0
}
/* -------------------------------------------------------------------------------------------------------------------*/

/******************* Backup How-To's **********************/

/* For a page background image - use this  */
#pageContainer {
  background-repeat:no-repeat; 
  background-position:center;
  background-position:center top;
  background-size:100%
}

/* Some link color code from original Dalhousie custom CSS
h1 a,h1 a:link,h1 a:visited{color:#232323;text-decoration:none}
h1 a:active,h1 a:focus,h1 a:hover{color:#626262}
*/

/* Footer Logo sizing */
/* .footer1 .footerAbout img {
width: 200px;
height: auto;
margin-bottom: 5px;
}
*/
/* TODO: In CAP in top-left is a book-bird, can change to school logo */
.oneceBookbird {
/*background: no-repeat url("/upload/LogoHeaderNameBrand.png") 5px 0px;
width: 50px;
height: 28px;
float: left;
*/
}

/* TODO: In CAP in top-right is a background book-bird, can change to school logo */
#mainContentContainer.container {
/*  background-color: #FFFFFF;
  background-image: url('../../../../images/Bookbird.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 95% 0%;
*/
}

/* No way to hide #header2Wrapper from PV but not from CLP */
/* #header2Wrapper {
    display:none;
}
*/

/* --------------------------------------------------------------------------------------------------------------------------  */
/* START Top Header Logo sizing - (Demo build only?) ********************************/
#schoolHeaderLogoWrapperLink {
/*  background: no-repeat url("/upload/LogoHeader.png") 0px 0px; */
  background-color: transparent;
  display: block;
  float: left;
  width: 250px; /* To change width, change this value.  This should not exist 320px (width of iphone 5). */
  height: 50px; /* To change height, change this value */
}

#quickSiteSearch {
  float: right;
  margin-top: 3px; /* Adjust this value when height is changed, no formula, keep increasing 1 till the search bar is vertically center */
  width: 100%;
}

#loginInfo,#cartInfo {
  line-height: auto; /* Adjust this value when height is changed, the formula should be (logo height + 3) */
}
/* Tablet view */

/* Desktop view */
@media ( min-width : 768px) {
  #quickSiteSearch {
    width: 40%; /* Adjust this to change the search bar width when the logo width is changed. */
  }
}
/* Tablet view - must be at the end */
@media ( min-width : 768px) and (max-width: 1000px) {
  #quickSiteSearch {
  width: 60px;
  display:none;
  }
}
/* *****   END Top Header Logo sizing ********************************/
/*************************************************************/

/* --------------------------------------------------------------------------------------------------------------------------  */
/******************* START - Destiny One improvements **********************/

/* Section Header  - this is the "Enroll Now - " header on course page.  Make it look like it's not a button. */
.sectionHeader {
  background-image: none;
  background-color: transparent;
  border-color: transparent;
}
.sectionHeader h3, .sectionHeader h2, .card-title  h2{
  color: #232323;
  font-size:1.8em;
}

.card-info>.card-header {
    color: #000;
    background-color:$mid-gray;
    border-color: #232323;
    .btn {
      background-color:$mid-gray;
    }
}
/* Secondary Section Header (i.e. if there are two headers on same page, the 2nd one needs to NOT look like a button too
e.g. If there's an Application then "Enroll Now - ... " becomes SectionHeaderSecondary*/
.sectionHeaderSecondary {
  background-image: none;
  background-color: transparent;
  border-color: transparent;
  color: #232323;
}

/* Hiding the misleading "Application List Checklist that has hard-coded "Status of Items Needed" */
.applicationListChecklist, #studentApplicationsListLegend
{
display:none;
}
/* Hiding the subforms from the PV student profile" #studentProfileDirectBillingAccountContainer,*/
#enrollmentTimeframes, #studentInfoNameTagName, #studentInfoOtherNamesOnRecord
{
display:none;
}

/* on CAP, this sets the width so the last letter does NOT wrap for "student@destinysolutions.com" */
.corporateAdminPortal td.cellMemberEmail {
    width: 218px;
}

/*Making the SR link on section profile NOT bold - for consistency */
.sectionSpecialRequests .specialRequestName {
font-weight: normal;
}

/* text size for labels */
h1,h2,h3,h4,h5,h6{line-height:1.2em}
h1{font-size:2.1em; margin-top: 1rem}
h2{font-size:1.8em;margin-bottom:.5em}
h5{font-size:1em;font-weight:normal}
h6{font-size:.8em;margin:0 0 .5em}

/* CLP: Add some spacing between school logo and top header */
#schoolCorporateLogoWrapper img{
	margin-top: 14px;
}

/* Link styles*/
a,a:link{
  text-decoration:none;
  color: #000;
}
a:active,a:focus,a:hover{
  text-decoration:underline;
}

/* All checkout breadcrumb styling - to make active page bolder .breadcrumb>.active {
font-weight: bolder;
}

****************** END - Destiny One improvements **********************/
/*************************************************************/


/* --------------------------------------------------------------------------------------------------------------------------  */
/******************* START - NameBrand University **********************/

/* Header 1 - this is the top bar.  #000000 is black. #2C76C7 is NameBrand Blue*/
.navbar-dark.bg-dark {
  background-image: none;
  background-color: #232323;
}

/* Footer DIV as defined in Content Management block */
#About {
  background-color: transparent;
  display: block;
  float: left;
  width: 100%; /* To change width, change this value.  This should not exceed 320px (width of iphone 5). */
  height: 50px; /* To change height, change this value */
  margin-bottom: 10px;
}

/* All checkout breadcrumb styling - to make active page bolder */
.breadcrumb>.active {
color: #232323;
}

/******************* END - NameBrand University **********************/
/* --------------------------------------------------------------------------------------------------------------------------  */

/*****************************************************/
/* START - White Header 1 custom branding *************/

/* Header 1 - this is the top bar.  #000000 is black, #FFFFFF is white.  page */
.navbar-dark.bg-dark {
  background-color: white !important;
}
/* Workaround for limitation to inability to scope to PV only is to reset CAP / Agent Portal back to desired color */
.corporateAdminPortal .navbar-dark.bg-dark
{
  background-color: #232323;
}

/* Remove borders from various headers and footers */
#header1Wrapper, #footer1Wrapper .container, #footer2Wrapper .container, #mainContentContainer.container, #header2Container
{
	border: none;
}

/* White header requires a header school logo with white or transparent background */
#schoolHeaderLogoWrapperLink {
  background: no-repeat url("/upload/DalhousieLogo.png") 0px 0px;  /* 50 x 250px */
}

/* Cart color, Login text color in Header 1 can no longer be white ... make it a dark enough color*/
.glyphicon-shopping-cart, #cartInfo a, .headerStudentLoginContainer .headerStudentLoginDropdownLink, div#loginInfo, #loginInfo .headerProfileLink, #loginInfo .headerStudentLoginLogoutLink
{
  color: #232323;  /* black or dark grey may be suitable as well */
}
/* Workaround for limitation to inability to scope to PV only is to reset CAP / Agent Portal / CLP back to white */
// .corporateAdminPortal .glyphicon-shopping-cart, .corporateAdminPortal #cartInfo a, .corporateAdminPortal .headerStudentLoginContainer .headerStudentLoginDropdownLink,  #clpHeader1ContentWrapper .glyphicon-shopping-cart, #clpHeader1ContentWrapper  #cartInfo a, .corporateAdminPortal #loginInfo, .corporateAdminPortal #loginInfo .headerProfileLink, .corporateAdminPortal #loginInfo .headerStudentLoginLogoutLink
// {
//   color: white;  /* black or dark grey may be suitable as well */
// }

/* Extend color to full page width for Header 3 menu bar */

/* Text color of top-level menu labels */
.navbar-light.bg-light .navbar-nav > li > a {
  text-shadow: none;
}

.navbar-nav>li {
	font-weight: bold;
	font-size: 1em;
}
/* Text color of top-level hover, focus, sub-menu labels */
.navbar-light.bg-light .navbar-nav>li>a:hover, .navbar-light.bg-light .navbar-nav>li>a:focus, .navbar-light.bg-light .navbar-nav>.open>a, .navbar-light.bg-light .navbar-nav>.open>a:hover, .navbar-light.bg-light .navbar-nav>.open>a:focus
{
  color: #C08C0C;
  background-color: transparent;
}

/* Main page background */
#pageContainer {
   background-color: white;
}

/*Extend footers to full width of page */
div#footer1Wrapper {
}

/***************************************/
/* START custom demo branding ***************/
/* Replace <color_1> with primary color of brand */
/* Replace <color_2> with secondary color of brand */
#schoolHeaderLogoWrapperLink {
/*  background: no-repeat url("/upload/LogoHeaderSCHOOL.png") 0px 0px; /*  /* 50 x 250px */
}

#About {
/*  background: no-repeat url("/upload/LogoFooterSCHOOL.png") 0px 0px; */
}

#contentModule5
{
 display:none;   /* Hides "News & Media", "Announcements", and "Highlights" from the home page */
}

/* Header 3 global navigation menu */
.navbar-light.bg-light, #header3Container, #header3Wrapper, .corporateAdminPortal .navbar-dark.bg-dark
{
/*   background-color: #color_1;*/
}

/* Cart color, Login text color in Header 1 can no longer be white ... make it a dark enough color*/
.glyphicon-shopping-cart, #cartInfo a, .headerStudentLoginContainer .headerStudentLoginDropdownLink, div#loginInfo, #loginInfo .headerProfileLink, #loginInfo .headerStudentLoginLogoutLink
{
/*  color: #color_1;  */ /* black or dark grey may be suitable as well */
}
/* Workaround for limitation to inability to scope to PV only is to reset CAP / Agent Portal / CLP back to white */
// .corporateAdminPortal .glyphicon-shopping-cart, .corporateAdminPortal #cartInfo a, .corporateAdminPortal .headerStudentLoginContainer .headerStudentLoginDropdownLink,  #clpHeader1ContentWrapper .glyphicon-shopping-cart, #clpHeader1ContentWrapper  #cartInfo a, .corporateAdminPortal #loginInfo, .corporateAdminPortal #loginInfo .headerProfileLink, .corporateAdminPortal #loginInfo .headerStudentLoginLogoutLink
// {
//   color: white;  /* black or dark grey may be suitable as well */
//}
.newsItemHeader {
text-decoration: underline;
}
/* Secondary Labels */

/* END custom demo branding *************
/***************************************/

.btn {
	border-radius: 0;
}

.btn-primary, .btn-secondary {
	border-radius: 0;
	background-image: none;
}

.btn-primary {
  background-color:$accent1;
  border:none;
  color:#000;
}

.btn-primary:hover {
	background-color: #707372;
	background-image: none;
}

.courseProfileSectionAvailability {
	color: #ee0701;
}

.courseProfileSectionAvailability_available {
	color: #000;
}

/* Hide "Request Information" menu */

#nav7 {
	display: none;
} div#searchBreadcrumb{ display:none; }


.studentProfileUsernamePanel{

	visibility: hidden;

}

#header3Wrapper .container {
	max-width: none;
}

#header3.row {
	width: 100%;
	margin: 0px;
}

.glyphicon {
	font-size: 1.5rem;
}
.footer2 {
	margin: 0;
	padding: 10px 0;
}
