#toc {
    padding:0 0 100px;
    h3{
        padding-bottom:0;
        &:hover{
            color:$primary-color;
        }        
    }
}
.faq{
    padding:50px 0 70px;
    h1,h2,h3,h4,h6 {
        font-weight: 600;
    }
    h1,h2,h3{
        color:#000;
        font-family: $display;
    }
    h4{
        color:$primary-color;
        font-size:18px;
        padding-bottom:10px;
        font-family: $display;
    }
    h1{
        text-align: center;
    }
    h1,h2{
        font-size:28px;
        position: relative;
        text-align: center;
        padding-bottom:4px;
        margin-bottom: 60px;
        font-weight: 500;
        &:after{
            content: '';
            height: 2px;
            max-width: 330px;
            background-color: #000;
            position: absolute;
            bottom: -10px;
            margin: 0 auto;
            left: 0;
            right: 0;
        }
    }
    h3{
        font-size: 20px;
        padding-bottom: 18px;
    }
    p{
        
    }
    table{
        border:none;
        td{
            border:none;
        }
    }
}