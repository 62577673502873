.testimonial {
    padding: 70px 0;
    .container{
        display: flex;
        max-width: 80%;
        @media screen and (max-width:800px) {
            flex-direction: column;
        }   
    }    
    &-image {
        object-fit: contain;
    }
    &-words {
        align-items: center;
        display: flex;     
        @media screen and (max-width:800px) {
            flex-direction: column;
        }   
        h4{
            font-weight: 400;
            font-size: 30px;
            line-height: 46px;
            @media screen and (min-width:800px) {
                padding-right:40px;
            }
        }
    }
}