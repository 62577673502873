#header1 {
    padding:0;
    .utility-nav {
        display:none;
    }
}
#header3Wrapper{
    border-bottom:3px solid $accent1;
    padding: 0 0 10px;
    .container{
        padding:0;
    }
    #header3 {
        max-width: 1060px;
        margin: 0 auto;
        .navbar-toggler,
        .d-md-none{
            display:none !important;
        }
        .navbar-nav {
            width: 100%;
            .dropdown-menu {
                right: 0;
                left: auto;
                border: 1px solid #ccc;
            }
        }
        .col-lg-12{
            padding:0;
        }
        #globalNavigationBar {
            #mainNavBarCollapsable {
                display: block !important;
            } 
            .wrapper {
                flex-direction: row;
                display: flex;
                justify-content: space-between;
                width: 100%;
                .logo-container {
                    display: flex;
                    gap: 15px;
                    justify-content: center;
                    align-items: center;
                    #capGroupInfoHeader{
                        display: flex;
                        flex-direction: column;
                    }
                }
                .main-logo {
                    max-width: 200px;
                }
                nav.main-nav {
                    @media screen and (min-width:940px) {
                        display: flex !important;
                    }
                    @media screen and (max-width:939px) {
                        display:none;
                    }
                    > .nav-item {
                        @media screen and (max-width:600px) {
                            padding-left: 20px;
                        }
                    }
                    .nav-link{
                        @media screen and (max-width:1000px){
                            padding:.5rem 10px;
                        }
                    }
                    a {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 27px;
                        color:#000;
                        font-family:$display;
                    }
                    .fa{
                        color:#000;
                    }
                    @media screen and (min-width:940px) {
                        justify-content: center;
                        align-items: center;
                    }
                    @media screen and (max-width:939px) {
                        flex-direction: column;
                        background-color: #ffffff;
                        position: absolute;
                        top: 93px;
                        left:0px;
                        width: 100%;
                        z-index: 60;
                        padding: 20px 0;
                        border-bottom:3px solid $accent1;
                    }
                }
                .mobile-nav{
                    display: none;
                    background-color: #F9F9F9;
                    padding: 20px 10px;
                    h6{
                        font-size: .8em;
                        margin: 0 0 0.5em;
                        color: #000;
                        font-weight: bold;
                        padding-left: 20px;
                    }
                    li{
                        padding: 5px 0;
                        padding: 5px 20px;
                    }
                    @media screen and (max-width:939px) {
                        display: block;
                    }
                }
            }       
        }
    }  
    .utility-nav,
    .utility-nav-alt {
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        @media screen and (max-width:939px) {
            width: 200px;
        }
        @media screen and (max-width: 768px){
            width: 100px;
        }
        &.loggged-in { //corporate portal
            justify-content: flex-end;
            #loginInfo{
                display: flex !important;
                gap: 5px;
                justify-content: flex-end;
                .labelWithSeparator:after {
                    padding-left: 4px;
                }
            }
            #logoutLinkWrapper{
                width: auto;
                max-width: none;
                padding: 0;
            }
        }  
        #log-off-button {
            background-color: #000;
            color: #fff;
            padding: 6px 10px;
        }
        #loginNameWrapper{ 
            width: auto;
        }
        #cartInfo{
            position: relative;
            text-align: left;
            #cartCount{
                color:#000;
                background-color:$accent1;
                font-family: $display;
                top: 0;
                right: -7px;
            }     
            .glyphicon-shopping-cart:before {
                color:#000;
            }
        }
        .headerStudentLoginDropdownLink{
            background-color:#000;
            color:#fff;
            padding:10px;
        }   
        .menu-toggle {
            width: 30px;
            height: 30px;
            cursor: pointer;
            z-index: 100000;
            display: block;
            margin-top: -10px;
            @media screen and (min-width:940px) {
                display: none;
            }
            @media screen and (max-width: $mobile) {
                right:15px;
                margin-top: -14px;
            }
            .one,
            .two,
            .three {
                width: 100%;
                height: 5px;
                margin: 6px auto;
                backface-visibility: hidden;
                background: #000;	
                -moz-transition-duration: 0.3s;
                -o-transition-duration: 0.3s;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;		
            }
            &.on {
                .one {
                    @include transform(rotate(45deg) translate(7px, 7px));
                }
                .two {
                    opacity: 0;
                }
                .three {
                    @include transform(rotate(-45deg) translate(8px, -9px));
                }
    
            }		
        }
    } 
    .portalMenuDropDownContainer {
        position: absolute;
        top: -10px;
        right: 0;
        a{
            color: #000;
        }
    }
}
#header3Wrapper{
    z-index: 3;
    position: relative;
}

.corporateAdminPortal  {
    .utility-nav{
        justify-content: flex-end !important;
        gap: 20px;
    }
    .loginInfo, .headerLogoutLink {
        color:#000 !important;
    }
}