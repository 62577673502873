::-moz-selection { 
  color: #ffffff;
  background-color:#000;
}
::selection {
  color: #ffffff;
  background-color:#000;
}

.bg-light{
  background-color: #fff !important;
}

img {
  max-width: 100%;
}

#pagePublicHome{
  #mainContentWrapper{
    margin-top:0;
  }
}

#mainContentWrapper{
  margin-top:40px;
}

.page-link{
  color:#000;
  border: 1px solid #000;
}
.page-item.active .page-link{
  background-color: #000;
}

#courseProfileActions{
  .btn{
    background: transparent; 
  }  
}

.btn{
  background-color:$accent1;
  color:#000;
  padding:10px 15px;
  font-weight: 600;
}

.btn-primary, .btn-secondary {
  border:none;
}

#navbar-toggler {
  display: none;
}

.cartCheckoutButton .cartCheckoutLockIcon {
  color:#000;
}

#contentHeader {
  border-bottom:3px solid #000;
}

.dropdown-menu > .active > a, 
.dropdown-menu > .active > a:hover, 
.dropdown-menu > .active > a:active, 
.dropdown-menu > .active > a:focus, 
.dropdown-menu .subMenu > .active > a, 
.dropdown-menu .subMenu > .active > a:hover, 
.dropdown-menu .subMenu > .active > a:active, 
.dropdown-menu .subMenu > .active > a:focus {
  background-color: $light-gray;
}

.variable-content a:link ,
#courseProfile a:link {
 text-decoration: underline;
}

#header2Wrapper{
  background-color: $accent1 !important;
  margin-bottom: 10px;
  display:none;
  padding-top:4px;
  .variable-content-item{
    color:#000;
    text-align: center;
    p{
      margin-bottom: 0;
      margin-right:0px;
      display: inline;
      font-size: .9em;
    }
    sup {
      top: 0.5em;
    }
    #read-more-tp{
      display:none;
    }
    #read-more-trigger{
      display: inline-block;
      text-decoration: underline;
      cursor: pointer;
      font-size: .9em;
      font-weight: bold;
    }
  }
}

.catalogSearchResults .firstColumn {
  display: block !important;
}