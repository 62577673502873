#pageCapLogin {
    #contentHeader {
        border-bottom: 0;
        padding-bottom: 40px;
        #pageHeader {
            text-align: center;
        }
    }
    #capLoginContainer {
        background-color: #F8F8F8;
        text-align: center;
        max-width: 400px;
        margin: 0 auto;
        padding: 10px 0 30px;    
        @include border-radius(20px);
        @media screen and (max-width:$break) {
            max-width: 100%;
            margin: 20px 30px 0;
        }
        .form-group{
            label {
                font-weight: bold;
            }
            select {
                max-width: 140px;
                margin: 5px auto;
            }
        }
        .buttons {
            display: flex;
            justify-content: center;
        }
    }
}

#pageCorporateLandingPage,
#pageCLPCourseProfile {
    background-color: #fff;
    #mainContentWrapper {
        background-color: #fff;
    }
}