#studentInquiryTypes,
#studentRequestInformation {
    #mainContentContainer {
        #contentHeader{
            text-align: center;
            border:none;
            h1{
                color:#000;
                text-transform: capitalize;
            }            
        }
        #contentModule1 {
            > .row > .col-lg-12 > p:first-child {
                text-align: center;
                width: 500px;
                margin: 0px auto 60px;
            }
        }
        .studentInquirySelection{
            margin-bottom: 25px;
            &#studentInquirySelectionType0{
                border-top: 1px solid #e0e0e0;
                padding-top: 25px;
            }
            &#studentInquirySelectionType2,
            &#studentInquirySelectionType3,
            &#studentInquirySelectionType4,
            &#studentInquirySelectionType10 {
                display:none;
            }
        }
        h5 a {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 10px;
            display: block;
        }
        p{
            font-size: 14px;
        }
    }
}

#studentRequestInformation #mainContentContainer {
    max-width: 700px;
    .characterCounter{
        padding-top:10px;
    }
    .buttons {
        text-align: center;
        .btn-primary{
            float: none;
            padding: 7px 20px;
        }
    }
    #requiredFieldInstruction{
        text-align: center;
        padding-top: 20px;
    }
}
#studentInquiryTypes #mainContentContainer {
    max-width: 900px;
}