.panel{
    margin-bottom: 40px;
    .wrapper {
        display: flex;
        @media screen and (max-width:800px) {
            flex-direction: column;
        }           
    }
    &-content,
    &-image {
        @media screen and (min-width:800px){
            width: 50%;
        }
    }
    &-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right:30px;
        @media screen and (max-width:800px) {
            padding:40px 0;
        }            
        h3{
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 12px;
        }
    }
}