.programs{
    padding-top:40px;
    h3 {
        font-weight: 600;
        margin-bottom: 29px;
        font-size: 28px;
    }
    .wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .program-item {
        background-color: #F8F8F8;
        padding:15px;
        width: calc(33.33% - 15px);
        margin-bottom: 15px;
        @media screen and (max-width:950px){
            width: calc(50% - 15px);
        }
        @media screen and (max-width:500px){
            width: 100%;
        }        
        .fa{
            margin-left:5px;
        }
        h4 {
            font-weight:600;
            font-size: 18px;
            margin-bottom:5px;
        }
    }
}