/*
 * Imports
*/
@import './scss/master/mixins';
@import './scss/master/variables';

@import './scss/master/global';
@import './scss/master/typo';
@import './scss/master/base';

@import './scss/elements/banner';
@import './scss/elements/mobile-toggle';
@import './scss/elements/pageHeader';

@import './scss/pages/cart'; 
@import './scss/pages/corporate';
@import './scss/pages/course';
@import './scss/pages/login';
@import './scss/pages/student';
@import './scss/pages/studentInquiry';

@import './scss/modules/faq';
@import './scss/modules/footer';
@import './scss/modules/header';
@import './scss/modules/info-props';
@import './scss/modules/panel';
@import './scss/modules/programs';
@import './scss/modules/testimonial';
@import './scss/modules/corporate';