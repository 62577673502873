#pageCourseProfile{
    .mainContentContainer{
        margin-top:40px;
    }
    #courseProfileRecommendedPrerequisites,
    .courseProfileCertificates{
        margin-top:15px;
    }
    #contentHeader{
        margin-bottom: 22px;
        padding-bottom: 18px;
        #pageHeader {
            .courseProfileInstructionMethods,
            .orgUnitPublicName{
                font-weight: 300;
                font-size: 18px;
                line-height: 25px;
                color: #000;
            }        
        }
        #pageHeaderActions{
            .addthis_toolbox{
                display: none;
            }
        }   
    }
    #courseProfileFeaturedVideo{
        margin-bottom: 25px;
    }
    #courseProfileOfficialCourseDescription{
        margin-bottom:20px;
        h2{
            color:#000;
        }
    }
    #sectionEnrollmentHeader{
        border:1px solid #C4C4C4;
        border-radius: 0;
    }
    //article 
    #courseProfile{
        li {
            line-height: 26px;
            margin-bottom: 4px;
        }
        .callout {
            padding: 15px;
            text-align: center;
            background-color: $light-gray;
            padding: 15px 25px;
            line-height: 24px;
        }
    }
    .courseSection{
        border:1px solid #000;
        margin-bottom:20px;
        border-radius: 0;
        .card-header {
            padding: 20px 23px;
            border-radius: 0;
            background-color:$mid-gray;
            .form-row {
                flex-direction: column;
                .courseSectionTitle{
                    font-size: 16px;
                    margin-bottom: 4px;
                }
                * {
                    font-family: $display;
                    max-width: 100%;
                    text-align: left;
                    position: initial;
                    .sr-only{
                        position: absolute;
                    }
                }
                .glyphicon{
                    font-family: 'Font Awesome 5 Free';
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
            .courseProfileSectionAvailability {
                margin-top: 6px;
                font-size: 16px;
                display: block;
            }
        }
    }
    .card-info{
        border-radius: 0;
    }
    .card-body{
        .buttons{
            padding-top:0;
        }
        .courseSectionEnrollmentActions {
            display: flex;
        }
        .courseSectionEnrollmentActions button{
            font-weight: bold;
            padding: 11px 21px;
            font-size: 16px;
            margin-bottom: 10px;
            &:hover{
                background-color:#000;
                color:$accent1;
            }
        }
    }
    .section.sectionSchedule{
        padding-top:16px;
    }
}