.menu-toggle {
    width: 30px;
    height: 30px;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 100000;
    @media screen and (max-width:320px){
        top:9px;
    }
    @media screen and (max-width: 759px) {
        right:25px;
        display: block;
    }
    &.on {
        .one {
            @include transform(rotate(45deg) translate(7px, 7px));
        }
        .two {
            opacity: 0;
        }
        .three {
            @include transform(rotate(-45deg) translate(8px, -9px));
        }
        .one,
        .two,
        .three {
            background:$mid-gray; 
        }
    }
    .one,
    .two,
    .three {
        width: 100%;
        height: 5px;
        background: $mid-gray;
        margin: 6px auto;
        backface-visibility: hidden;
        -moz-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        position:relative;
        z-index:20;
    }
}    